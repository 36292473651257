import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  ADD_TYPE_DOCUMENT,
  createConfigService,
  CREATE_GLOBAL_CONFIG,
  DELETE_ONE_TYPE_DOCUMENT,
  getConfigService,
  GET_ALL_TYPE_DOCUMENT,
  updateConfigService,
  UPDATE_ONE_TYPE_DOCUMENT
} from 'src/services/configs';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { Main, Footer, TabDocuments, TabAccess } from './styles';
import TzMap from './tzMap';
import { ALTER_PACK_CONFIG } from './_redux/actions';
import { ConfigSystemProps } from './_redux/types';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
import { ListDocuments } from './components/ListDocuments';

import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import SVG from 'react-inlinesvg';

import { Button, Modal } from 'react-bootstrap';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { Show } from 'src/app/components/Show';
import { LicensesTab } from './components/LicensesTab';
import { RemoteOnboard } from './components/RemoteOnboard';
import { Input } from '../vehicles/pages/CreateVehiclePage/components/Input';
import { TextField } from '@mui/material';
import { CustomLabels } from 'src/utils/customLabels';
import { InputsConfigs } from './components/inputsConfigs';
import { defaultDateTime } from 'src/utils/defaultDateTime';
import { ConfigDateTime } from 'src/app/components/configDateTime';

import * as yup from 'yup'
import { Select } from './components/inputs/Select';
// import MUIRichTextEditor from "mui-rte";
// Habilitar envio de mensagem no e-mail do qr code
// Quando habilitado, permite que você envie uma mensagem padrão no corpo do e-mail do qr code.

type DocumentProps = {
  id: number;
  name: string;
  block: boolean;
  isExpirationDate: boolean;
  isImage: boolean;
  allowDuplicity: boolean;
};

type RemoteOnBoardingRequiredFields = {
  email: boolean;
  personPhoto: boolean;
  personDocuments: boolean;
  phoneNumber: boolean;
};

type InactivationTime = {
  isEnabled: boolean;
  value: number;
  error: string;
  isDirty: boolean;
};

type RemoteOnBoardingRequiredFieldsReducer = (
  s: RemoteOnBoardingRequiredFields,
  value: Partial<RemoteOnBoardingRequiredFields>
) => RemoteOnBoardingRequiredFields;

const validateInactivationTime = (data: InactivationTime) => {
  return data.isEnabled && data.value === 0 ? 'Número de Dias não pode ser zero' : '';
};

export interface HandlerAlterInputConfigProps {
  value: boolean,
  view: 'visitor' | 'people',
  input: string
}

export function Config() {

  const [allowParkingCreation, setAllowParkingCreation] = useState(false)
  const { redirectView, actionsIsDesatived } = usePermisssion();
  const [isRead, setIsRead] = useState(false);
  const [isCreateOrEditDocument, setIsCreateOrEditDocument] = useState(false);
  const [documents, setDocuments] = useState<DocumentProps[]>([]);
  const [modalDeleteIsShow, setModalDeleteIsShow] = useState(false);
  const [dataModal, setDataModel] = useState({
    id: 0,
    name: ''
  });

  const [targetInactivationAfterExpire, setTargetInactivationAfterExpire] = useState(0)
  const [targetAutomaticPersonInactivation, setTargetAutomaticPersonInactivation] = useState(0)

  const [inputsConfiguration, setInputsConfiguration] = useState({
    visitor: {
      isRequiredCPF: false,
      isValidationCPF: false,
    },
    people: {
      isRequiredCPF: false,
      isValidationCPF: false,
    },
  })

  function handlerAlterInputConfig({ input, value, view }: HandlerAlterInputConfigProps) {

    const newValuesRaw = JSON.stringify(inputsConfiguration)
    const newValues = JSON.parse(newValuesRaw)


    newValues[view][input] = value


    setInputsConfiguration(newValues)
  }

  const [personalizedPersonLabel, setPersonalizedPersonLabel] = useState('')
  const [personalizedMessageVisitorLabel, setPersonalizedMessageVisitorLabel] = useState('')

  const [typeDocument, setTypeDocument] = useState('');
  const [typeDocumentIsBlock, setTypeDocumentIsBlock] = useState(false);
  const [allowDuplicity, setAllowDuplicity] = useState(false);

  const [documentRequiredInPersons, setDocumentRequiredInPersons] = useState(false);
  const [documentRequiredInVisitors, setDocumentRequiredInVisitors] = useState(false);
  const [checkDocumentValidation, setCheckDocumentValidation] = useState(false);

  const [typeDocumentBlockUserIsDocumentDateExpired, setTypeDocumentBlockUserIsDocumentDateExpired] = useState(false);
  const [typeDocumentIsImage, setTypeDocumentIsImage] = useState(false);
  const [typeDocumentIdEditing, setTypeDocumentIdEditing] = useState(0);


  const [automaticInactivationDeadline, setAutomaticInactivationDeadline] = useState(false)
  const [enableCreditControl, setEnableCreditControl] = useState(false);
  const [enableRandomSecurityCheck, setEnableRandomSecurityCheck] = useState(false);
  const [enableAccessMethodsConfig, setEnableAccessMethodsConfig] = useState(false);
  const [enableDeviceInAcessRules, setEnableDeviceInAcessRules] = useState(false);
  const [enableResetStatusCurrentPeoples, setEnableResetStatusCurrentPeoples] = useState(false);
  const [disableVisitorAccessingExternalArea, setDisableVisitorAccessingExternalArea] = useState(false);
  const [disablePersonCardsWhenPersonInactive, setDisablePersonCardsWhenPersonInactive] = useState(false);
  const [disablePersonCardsWhenVisitorInactive, setDisablePersonCardsWhenVisitorInactive] = useState(false);
  const [qrCodeActive, setQrCodeActive] = useState(false);
  const [qrCodeErro, setQrCodeErro] = useState('');
  const [qrCodeErroBoolean, setQrCodeErroBoolean] = useState(false);
  const [qrCodeMessage, setQrCodeMessage] = useState('');
  const [defaultMessageActive, setDefaultMessageActive] = useState(false);
  const [defaultMessageBoolean, setDefaultMessageBoolean] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState('');
  const [defaultMessageErro, setDefaultMessageErro] = useState('');
  const [visitorControlToggle, setVisitorControlToggle] = useState(false);
  const [visitorControlSelect, setVisitorControlSelect] = useState(0);
  const [inactivationTime, setInactivationTime] = useReducer<
    (s: InactivationTime, v: Partial<InactivationTime>) => InactivationTime
  >((s, v) => ({ ...s, ...v, error: v.isDirty || s.isDirty ? validateInactivationTime({ ...s, ...v }) : '' }), {
    isEnabled: false,
    value: 0,
    error: '',
    isDirty: false
  });

  const [remoteOnBoardingRequiredFields, setRemoteOnBoardingRequiredFields] = useReducer<
    RemoteOnBoardingRequiredFieldsReducer
  >((state, value) => ({ ...state, ...value }), {
    personPhoto: false,
    email: false,
    personDocuments: false,
    phoneNumber: false
  });

  const history = useHistory();
  const intl = useIntl();
  const GLOBALCONFIGS = useSelector<any, ConfigSystemProps>((stage) => stage.configs);
  const GLOBAL = useSelector((stage: any) => stage.auth);
  const dispatch = useDispatch();
  const userActived = GLOBAL.authToken.userId;
  const [tabActived, seTabActived] = useState(0);
  const [isEdit, setIsEdit] = useState(0);
  // const [language, setLanguage_] = useState<string>(GLOBALCONFIGS.language);
  const [language, setLanguage_] = useState<string>('pt');
  const [documentPerson, setDocumentPerson] = useState<string>(GLOBALCONFIGS.personDocumentType);
  const [cardPerson, setCardPerson] = useState<number>(GLOBALCONFIGS.personCardType);
  const [timeZone, setTimeZone] = useState<number>(GLOBALCONFIGS.timeZone);
  const [timeZoneCode, setTimeZoneCode] = useState<string>(GLOBALCONFIGS.timeZoneCode);
  const [webHook, setWebhook] = useState(false);
  const [webHookURL, setWebhookURL] = useState('');
  const [timeAntiDoubleEntry, setTimeAntiDoubleEntry] = useState('');

  const [isSaveProgress, setIsSaveProgress] = useState(false)
  const [modelDay, setModelDay] = useState('dd')
  const [modelMonth, setModelMonth] = useState('MM')
  const [modelYear, setModelYear] = useState('yyyy')
  const [modelSeparator, setModelSeparator] = useState('/')

  const [modelHour, setModelHour] = useState('HH')
  const [modelMinutes, setModelMinutes] = useState('mm')
  const [randomInspectLockedPeriod, setRandomInspectLockedPeriod] = useState(0)
  const [randomInspectActivateRelay, setRandomInspectActivateRelay] = useState(false)

  const [order, setOrder] = useState(0)
  const AM = modelHour.includes('h') ? 'a' : ''
  const templatesDateTime = [
    {
      time: `${modelHour}:${modelMinutes} ${AM}`,
      date: `${modelDay}${modelSeparator}${modelMonth}${modelSeparator}${modelYear}`
    },

    {
      time: `${modelHour}:${modelMinutes} ${AM}`,
      date: `${modelYear}${modelSeparator}${modelMonth}${modelSeparator}${modelDay}`
    },

    {
      time: `${modelHour}:${modelMinutes} ${AM}`,
      date: `${modelMonth}${modelSeparator}${modelDay}${modelSeparator}${modelYear}`
    },

    {
      time: `${modelHour}:${modelMinutes} ${AM}`,
      date: `${modelYear}${modelSeparator}${modelDay}${modelSeparator}${modelMonth}`
    }
  ]


  function handleSelectedValue({ target }: React.ChangeEvent<HTMLSelectElement>) {
    switch (target.name) {
      case 'language':
        setLanguage_(target.value);
        break;
      case 'documentDefaultToPerson':
        setDocumentPerson(target.value);
        break;
      case 'cardDefaultToPerson':
        setCardPerson(Number(target.value));
        break;
      default:
        break;
    }
  }

  function restoreOrder(value: string) {

    try {
      const templates: string[] = []

      document.querySelectorAll('#templates-order-format-date option').forEach((option: any) => {
        templates.push(option.innerText)
      })

      const positionTemplate = templates.findIndex(template => template.includes(value))

      if (positionTemplate) {

        if (positionTemplate === -1) {
          return
        } else {
          setOrder(positionTemplate)
        }
      }
    } catch (e) {

    }
  }

  function HelperTotargetAutomaticPersonInactivation(value: number) {
    switch (value) {
      case 1: return 'Person'
      case 2: return 'Visitor'
      default: return 'Both'
    }
  }

  async function handleSave() {
    if (isRead) return;

    if (webHookURL && !webHookURL.match(/[(http(s)):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i)) {
      execToast('error', intl.formatMessage({ id: "WEBHOOK.ERROR" }))
      return
    }

    const inactivationTimeError = validateInactivationTime(inactivationTime);
    if (!!inactivationTimeError) {
      setInactivationTime({ error: inactivationTimeError, isDirty: true });
      return execToast('error', 'Numero de Dias em Inativação Automática por Período não Pode ser Zero', false);
    }
    // // npm install mui-rte --save

    if (defaultMessageActive) {
      if (defaultMessage === '') {
        // CONFIG.ENABLE_SENDING_MESSAGE_ERROR
        setDefaultMessageErro(intl.formatMessage({ id: 'A mensagem padrão da lista de bloqueio precisa ser preenchida' }));
        setDefaultMessageBoolean(true);
        return;
      }
    }

    if (qrCodeActive) {
      if (qrCodeMessage === '') {
        // CONFIG.ENABLE_SENDING_MESSAGE_ERROR
        setQrCodeErro(intl.formatMessage({ id: 'CONFIG.ENABLE_SENDING_MESSAGE_ERROR' }));
        setQrCodeErroBoolean(true);
        return;
      }
    }

    try {
      setIsSaveProgress(true)

      let language_to_number = 0;

      if (language === 'pt') language_to_number = 1;
      if (language === 'en') language_to_number = 2;
      if (language === 'es') language_to_number = 3;
      if (language === 'fr') language_to_number = 4;

      // if (!isEdit) {
      //   await createConfigService({
      //     personId: userActived,
      //     defaultCompanyIDType: 'CNPJ',
      //     defaultLanguage: language_to_number,
      //     defaultDocumentType: documentPerson.toUpperCase(),
      //     defaultCardType: cardPerson,
      //     defaultTimeZone: timeZone,
      //     defaultTimeZoneCode: timeZoneCode
      //   });
      // } else {
      //   await updateConfigService({
      //     id: isEdit,
      //     personId: userActived,
      //     defaultCompanyIDType: 'CNPJ',
      //     defaultLanguage: language_to_number,
      //     defaultDocumentType: documentPerson.toUpperCase(),
      //     defaultCardType: cardPerson,
      //     defaultTimeZone: timeZone,
      //     defaultTimeZoneCode: timeZoneCode
      //   });
      // }


      if (!isEdit) {
        const dateDefault = new Date().toISOString().slice(0, 10);
        const pathConfig = {
          personalizedMessageForRemoteRegistration: personalizedMessageVisitorLabel,
          personalizedPersonLabel: personalizedPersonLabel,
          // 'antiDoubleEntryHour': `${dateDefault}T${timeAntiDoubleEntry}:00`,
          'cleaningPersonLocationTime': `${timeAntiDoubleEntry}:00`,
          'defaultDocumentTypeId': Number(documentPerson),
          'defaultCardType': cardPerson.toString(),
          'enableRandomSecurityCheck': enableRandomSecurityCheck,
          'enableCreditControl': enableCreditControl,
          'enableAccessMethodsConfig': enableAccessMethodsConfig,
          'enableAccessRuleDeviceRelationship': enableDeviceInAcessRules,
          'defaultTimeZone': timeZone,
          'defaultTimeZoneCode': timeZoneCode,
          'language': saveInputLanguage(language as any),
          'enableCleaningPersonLocation': enableResetStatusCurrentPeoples,
          'disableVisitorAccessingExternalArea': disableVisitorAccessingExternalArea,
          'enableVisitorControl': visitorControlToggle,
          'typeOfVisitControl': visitorControlSelect,
          'enablePersonalizedMessageForBlockedPersons': defaultMessageActive,
          'personalizedMessageForBlockedPersons': defaultMessage,
          disablePersonCardsWhenPersonInactive: disablePersonCardsWhenPersonInactive,
          disablePersonCardsWhenVisitorInactive: disablePersonCardsWhenVisitorInactive,
          enableEmailBodyWhenSendingQRCode: qrCodeActive,
          messageEmailBodyQRCode: qrCodeMessage,
          enableWebhookIntegration: webHook,
          webhookURL: webHookURL,
          mandatoryInputRemoteOnBoardings: Object.entries(remoteOnBoardingRequiredFields)
            .filter(([key, value]) => value)
            .map(([key]) => key),
          enableAutomaticPersonInactivation: inactivationTime.isEnabled,
          automaticInactivationThresholdInDays: inactivationTime.value,
          enableAutomaticInactivationAfterExpire: automaticInactivationDeadline,
          dateFormat: templatesDateTime[order].date,
          timeFormat: templatesDateTime[order].time,
          targetInactivationAfterExpire: targetInactivationAfterExpire,
          targetAutomaticPersonInactivation: targetAutomaticPersonInactivation,
          allowParkingCreation: allowParkingCreation,
          randomInspectLockedPeriod,
          randomInspectActivateRelay
        };

        defaultDateTime.setDefault({
          date: pathConfig.dateFormat,
          time: pathConfig.timeFormat
        })

        await CREATE_GLOBAL_CONFIG(pathConfig);
      }

      dispatch(
        ALTER_PACK_CONFIG({
          language,
          personDocumentType: documentPerson,
          personCardType: cardPerson,
          timeZone: timeZone,
          timeZoneCode: timeZoneCode
        })
      );

      const customLabel = new CustomLabels()

      customLabel.setCustomLabel({
        inputName: 'Pessoas',
        value: personalizedPersonLabel
      })

      customLabel.setCustomLabel({
        inputName: 'personalizedMessageForRemoteRegistration',
        value: personalizedMessageVisitorLabel
      })

      if (!isEdit) {
        execToast('success', intl.formatMessage({ id: 'CONFIG.RESPONSE.CREATE_SUCCESS' }), 3000);
      } else {
        execToast('success', intl.formatMessage({ id: 'CONFIG.RESPONSE.EDIT_SUCCESS' }), 3000);
      }
      history.push('/dashboard');
      //setTimeout(() => {
      // setLanguage(language);
      //}, 600);
    } catch {
      if (!isEdit) {
        execToast('error', intl.formatMessage({ id: 'CONFIG.RESPONSE.CREATE_ERROR' }), 3000);
      } else {
        execToast('error', intl.formatMessage({ id: 'CONFIG.RESPONSE.EDIT_ERROR' }), 3000);
      }

      setIsSaveProgress(false)
    }
  }

  async function handle_document_add() {
    if (!typeDocument) {
      execToast('error', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_ERROR_EMPTY' }), 3000);
      return;
    }

    const documentCurrent = {
      expirationBlockAccess: typeDocumentBlockUserIsDocumentDateExpired,
      hasExpirationDate: typeDocumentIsBlock,
      hasImageAttached: typeDocumentIsImage,
      description: typeDocument,
      allowDuplicity: allowDuplicity,

      requiredForPerson: documentRequiredInPersons,
      requiredForVisitor: documentRequiredInVisitors,
      validationRequired: checkDocumentValidation
    };


    try {
      await ADD_TYPE_DOCUMENT(documentCurrent);
      getListTypesDocuments();
      execToast('success', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_SUCCESS' }), 3000);
      setIsCreateOrEditDocument(false);
      setTypeDocument('');
      setTypeDocumentIsBlock(false);
      setTypeDocumentBlockUserIsDocumentDateExpired(false);
      setTypeDocumentIsImage(false);
    } catch (e) {
      if (409 === e.response.status) {
        execToast('error', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_ERROR_API' }), 3000);
        return;
      }
    }
  }

  async function handle_document_edit() {
    if (!typeDocument) {
      execToast('error', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_ERROR_EMPTY' }), 3000);
      return;
    }

    const documentCurrent = {
      expirationBlockAccess: typeDocumentBlockUserIsDocumentDateExpired,
      hasExpirationDate: typeDocumentIsBlock,
      hasImageAttached: typeDocumentIsImage,
      description: typeDocument,
      documentTypeId: typeDocumentIdEditing,
      allowDuplicity: allowDuplicity,
      requiredForPerson: documentRequiredInPersons,
      requiredForVisitor: documentRequiredInVisitors,
      validationRequired: checkDocumentValidation
    };

    try {
      await UPDATE_ONE_TYPE_DOCUMENT(documentCurrent);
      getListTypesDocuments();
      execToast('success', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_SUCCESS' }), 3000);
      setIsCreateOrEditDocument(false);
      setTypeDocument('');
      setTypeDocumentIsBlock(false);
      setTypeDocumentBlockUserIsDocumentDateExpired(false);
      setTypeDocumentIsImage(false);
    } catch (e) {
      if (409 === e.response.status) {
        execToast('error', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_ERROR_API' }), 3000);
        return;
      }
      execToast('error', intl.formatMessage({ id: 'CONFIG_TAB_FILES_ERRO_TYPE_DOC' }), 3000);
    }
  }

  async function handle_documente_delete() {
    try {
      await DELETE_ONE_TYPE_DOCUMENT(dataModal.name);
      getListTypesDocuments();

      execToast('success', intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOAST_SUCCESS_REMOVE' }), 3000);
    } catch (e) {
      if (e.response.status === 422) {
        execToast('error', intl.formatMessage({ id: 'DOCUMENT.ERROR.DELETE_API' }), 3000);
        return;
      }

      execToast('error', intl.formatMessage({ id: 'DOCUMENT.ERROR.DELETE_API_CPF_OR_RG' }), 3000);
    }
  }

  function RenderAction(item: number, name: string) {
    return (
      <>
        <button
          className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
          onClick={() => {
            setIsCreateOrEditDocument(true);
            const documentCurrent: any = documents.find((document) => document.id === item);

            if (documentCurrent) {
              setTypeDocument(documentCurrent.name);
              setTypeDocumentIsBlock(documentCurrent.isExpirationDate);

              setTypeDocumentBlockUserIsDocumentDateExpired(documentCurrent.block);
              setTypeDocumentIsImage(documentCurrent.isImage);

              setTypeDocumentIdEditing(item);
              setAllowDuplicity(documentCurrent?.allowDuplicity);

              setCheckDocumentValidation(documentCurrent.validationRequired)
              setDocumentRequiredInPersons(documentCurrent.requiredForPerson)
              setDocumentRequiredInVisitors(documentCurrent.requiredForVisitor)
            }
          }}>
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
          </span>
        </button>

        <a
          className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
          onClick={() => {
            setDataModel({
              id: item,
              name: name
            });

            setModalDeleteIsShow(true);
          }}>
          <span className='svg-icon svg-icon-md svg-icon-danger'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
          </span>
        </a>
      </>
    );
  }

  async function getListTypesDocuments() {
    const { data } = await GET_ALL_TYPE_DOCUMENT();

    const values = data.data.value;
    setDocuments(
      values.map((item: any) => {
        return {
          ...item,
          block: item.expirationBlockAccess,
          name: item.description,
          isImage: item.hasImageAttached,
          isExpirationDate: item.hasExpirationDate,
          allowDuplicity: item.allowDuplicity
        };
      })
    );
  }

  function dateRestore(date: string, divisor: string) {

    if (!date) return
    const values = date.split(divisor)
    setModelSeparator(divisor)

    values.forEach(result => {
      if (result.includes('d')) {
        setModelDay(result)
      }

      if (result.includes('M')) {
        setModelMonth(result)
      }

      if (result.includes('yy')) {
        setModelYear(result)
      }
    })


  }

  function timeRestore(value: string) {
    if (!value) return
    const [time, minutes] = value.split(':')

    setModelHour(time)

    if (minutes.includes(' ')) {
      const [m] = minutes.split(' ')
      setModelMinutes(m)
    }
  }


  function restoreInputLanguage(value: 'pt-br' | 'fr-fr' | 'en-us') {
    switch (value) {
      case 'fr-fr': {
        return 'fr'
      }
      case 'en-us': {
        return 'en'
      }

      default: {
        return 'pt'
      }
    }
  }

  function saveInputLanguage(value: 'pt' | 'fr' | 'en') {
    switch (value) {
      case 'fr': {
        return 'fr-fr'
      }
      case 'en': {
        return 'en-us'
      }
      default: {
        return 'pt-br'
      }
    }
  }

  useEffect(() => {
    const myConfigs = async () => {
      try {
        if (!userActived) return;
        const response = await getConfigService(userActived);
        const dataToStore = response.data.data;

        let language_to_number = '';

        if (dataToStore.defaultLanguage === 1) language_to_number = 'pt';
        if (dataToStore.defaultLanguage === 2) language_to_number = 'en';
        if (dataToStore.defaultLanguage === 3) language_to_number = 'es';
        if (dataToStore.defaultLanguage === 4) language_to_number = 'fr';

        setCardPerson(dataToStore.defaultCardType);
        setDocumentPerson(dataToStore.defaultDocumentTypeId);
        setLanguage_(language_to_number);
        setTimeZone(dataToStore.defaultTimeZone);
        setTimeZoneCode(dataToStore.defaultTimeZoneCode);
        setIsEdit(dataToStore.id);
      } catch (err) {
        setIsEdit(0);
      }
    };

    myConfigs();
  }, []);

  useEffect(() => {
    redirectView(11);
    const value = actionsIsDesatived(11);
    setIsRead(value);
  }, []);

  useEffect(() => {
    getListTypesDocuments();
  }, []);

  useEffect(() => {
    getConfigService(userActived).then((resp) => {
      setTimeAntiDoubleEntry(resp.data.data?.cleaningPersonLocationTime?.slice(0, 5));
      setLanguage_(restoreInputLanguage(resp.data.data.language));
      setEnableCreditControl(resp.data.data.enableCreditControl);
      setEnableRandomSecurityCheck(resp.data.data.enableRandomSecurityCheck);
      setEnableAccessMethodsConfig(resp.data.data.enableAccessMethodsConfig);
      setEnableDeviceInAcessRules(resp.data.data.enableAccessRuleDeviceRelationship);
      setEnableResetStatusCurrentPeoples(resp.data.data.enableCleaningPersonLocation);
      setDisableVisitorAccessingExternalArea(resp.data.data.disableVisitorAccessingExternalArea);
      setDisablePersonCardsWhenPersonInactive(resp.data.data.disablePersonCardsWhenPersonInactive);
      setDisablePersonCardsWhenVisitorInactive(resp.data.data.disablePersonCardsWhenVisitorInactive);
      setQrCodeActive(resp.data.data?.enableEmailBodyWhenSendingQRCode);
      setQrCodeMessage(resp.data.data?.messageEmailBodyQRCode);
      setVisitorControlToggle(resp.data.data?.enableVisitorControl);
      setVisitorControlSelect(resp.data.data?.typeOfVisitControl);
      setDefaultMessageActive(resp.data.data?.enablePersonalizedMessageForBlockedPersons);
      setDefaultMessage(resp.data.data?.personalizedMessageForBlockedPersons);
      setRemoteOnBoardingRequiredFields(
        resp.data.data.mandatoryInputRemoteOnBoardings?.reduce(
          (fields: Record<string, boolean>, field: string) => ({ ...fields, [field]: true }),
          {}
        ) || {}
      );
      setPersonalizedPersonLabel(resp.data.data?.personalizedPersonLabel)
      setPersonalizedMessageVisitorLabel(resp.data.data?.personalizedMessageForRemoteRegistration)
      setWebhook(resp.data.data.enableWebhookIntegration);

      setWebhookURL(resp.data.data.webhookURL);

      setInactivationTime({
        isEnabled: resp.data.data.enableAutomaticPersonInactivation,
        value: resp.data.data.automaticInactivationThresholdInDays
      });

      setAutomaticInactivationDeadline(resp.data.data.enableAutomaticInactivationAfterExpire)

      const default_date_time = defaultDateTime.getDefault()

      if (resp.data.data.dateFormat) {
        dateRestore(resp.data.data.dateFormat, resp.data.data.dateFormat.includes('/') ? '/' : '-')
      } else {
        dateRestore(default_date_time.date, default_date_time.date.includes('/') ? '/' : '-')
      }

      if (resp.data.data.timeFormat) {
        timeRestore(resp.data.data.timeFormat)
      } else {
        timeRestore(default_date_time.time)
      }


      restoreOrder(resp.data.data.dateFormat)
      setTargetInactivationAfterExpire(resp.data.data.targetInactivationAfterExpire)
      setTargetAutomaticPersonInactivation(resp.data.data.targetAutomaticPersonInactivation)

      setRandomInspectLockedPeriod(resp.data.data.randomInspectLockedPeriod)
      setRandomInspectActivateRelay(resp.data.data.randomInspectActivateRelay)

      setAllowParkingCreation(resp.data.data.allowParkingCreation)

    });
  }, []);

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        title={intl.formatMessage({ id: 'CONFIG.TITLE' })}>
        <CardHeaderToolbar className={null}>
          <div
            style={{
              opacity: 0
            }}>
            <button disabled={true} type='button' className='btn btn-primary ml-5 mr-2 p-2' onClick={() => { }}>
              helper
            </button>
          </div>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody fit={null} fluid={null} className=''>
        <div
          style={{
            padding: '0px 12px'
          }}>
          <div>
            <ul className='nav nav-dark nav-bold nav-tabs nav-tabs-line' data-remember-tab='tab_id' role='tablist'>
              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 0 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(0);
                  }}>
                  {intl.formatMessage({ id: 'CONFIG.TABS.GENERAL_TITLE' })}
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 1 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(1);
                  }}>
                  {intl.formatMessage({ id: 'CONFIG_TAB_FILES_NAME' })}
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 2 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(2);
                  }}>
                  {intl.formatMessage({ id: 'REPORTS.ACCESS' })}
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 6 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(6);
                  }}>
                  {intl.formatMessage({ id: 'Integrations' })}
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 3 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(3);
                  }}>
                  {intl.formatMessage({ id: 'REPORTS.LICENSES' })}
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 5 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(5);
                  }}>
                  {intl.formatMessage({ id: 'VISITOR.REMOTE_REGISTRATION' })}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 7 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(7);
                  }}>
                  {intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS' })}
                </a>
              </li>

              {/* <li className='nav-item'>
                <a
                  className={`nav-link ${tabActived === 8 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    seTabActived(8);
                  }}>
                  {intl.formatMessage({ id: 'Campos obrigatorios' })}
                </a>
              </li> */}
            </ul>
          </div>

          {tabActived === 0 && (
            <div className='tab-pane active'>
              <Main>
                <div>
                  <h5 className='weight-400'>{intl.formatMessage({ id: 'ASIDE_MENU.CONFIG_item' })}</h5>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <label>{intl.formatMessage({ id: 'CONFIG.LABEL_LANGUAGE' })}</label>
                      <select name='language' className='custom-select' value={language} onChange={handleSelectedValue}>
                        <option value='en'>{intl.formatMessage({ id: 'CONFIG.SELECT_LANGUAGUE_EN' })}</option>
                        <option value='pt'>{intl.formatMessage({ id: 'CONFIG.SELECT_LANGUAGUE_PT' })}</option>
                        <option value='es'>{intl.formatMessage({ id: 'CONFIG.SELECT_LANGUAGUE_ES' })}</option>
                        <option value='fr'>{intl.formatMessage({ id: 'CONFIG.SELECT_LANGUAGUE_FR' })}</option>
                      </select>
                    </div>
                    <div className='col-lg-3'>
                      <div className='row'>
                        <label className='ml-5'>{intl.formatMessage({ id: 'CONFIG.STANDART_TIMEZONE.TITLE' })}</label>
                        <TzMap data={timeZoneCode} setTimeZone={setTimeZone} setTimeZoneCode={setTimeZoneCode}></TzMap>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className='weight-400'>{intl.formatMessage({ id: 'CONFIG.SUBTITLE.PERSON' })}</h5>
                  <div className='row'>
                    <div className='col-lg-3'>
                      <label>{intl.formatMessage({ id: 'CONFIG.LABEL_DOCDEFAULT' })}</label>
                      <select
                        value={documentPerson}
                        name='documentDefaultToPerson'
                        className='custom-select'
                        onChange={handleSelectedValue}>
                        <option value={2}>
                          {
                            window.controlID.modules.configCulture.description === "Brasil" ? 'RG' : 'Passport'
                          }
                        </option>
                        <option value={1}>
                          {
                            window.controlID.modules.configCulture.description === "Brasil" ? 'CPF' : 'SSN'
                          }
                        </option>
                      </select>
                    </div>

                    <div className='col-lg-3'>
                      <label>{intl.formatMessage({ id: 'CONFIG.LABEL_TYPECARD' })}</label>
                      <select
                        value={cardPerson}
                        name='cardDefaultToPerson'
                        className='custom-select'
                        onChange={handleSelectedValue}>
                        <option value='1'>{intl.formatMessage({ id: 'PERSON.CARD.FORMATO.ASK_AREA_CODE' })}</option>
                        <option value='5'>{intl.formatMessage({ id: 'PERSON.CARD.FORMATO.ASK_DECIMAL' })}</option>
                        <option value='2'>{intl.formatMessage({ id: 'PERSON.CARD.FORMATO.MIFARE' })}</option>
                        {/* <option value='3'>QR CODE</option> */}
                      </select>
                    </div>


                  </div>

                  <div className='row'>
                    <div className='col-lg-3'>
                      <label>{intl.formatMessage({ id: 'CONFIG_NAME_LABEL' })}</label>
                      <input type="text" className='form-control' value={personalizedPersonLabel} onChange={({ target }) => {
                        setPersonalizedPersonLabel(target.value)
                      }} />
                    </div>

                    <div className='col-lg-3'>
                      <span style={{ display: 'block', marginBottom: '5px' }}>{intl.formatMessage({ id: 'Habilitar cadastro de vagas em pessoas e visitantes' })}</span>
                      <ToggleSwitch
                        checked={allowParkingCreation}
                        onChange={(e) => {
                          setAllowParkingCreation(e.target.checked);
                        }}
                      />
                    </div>
                  </div>


                </div>

                <div >
                  <ConfigDateTime
                    modelDay={modelDay}
                    modelHour={modelHour}
                    modelMinutes={modelMinutes}
                    modelMonth={modelMonth}
                    modelSeparator={modelSeparator}
                    modelYear={modelYear}
                    order={order}
                    setModelDay={setModelDay}
                    setModelHour={setModelHour}
                    setModelMinutes={setModelMinutes}
                    setModelMonth={setModelMonth}
                    setModelSeparator={setModelSeparator}
                    setModelYear={setModelYear}
                    setOrder={setOrder}
                  />
                </div>
              </Main>
            </div>
          )}

          {tabActived === 1 && (
            <TabDocuments className='tab-pane active'>
              {/* <header style={{display: 'flex', justifyContent: 'flex-end', position: "relative", zIndex:50}}>
                
              </header> */}

              <main>
                <ListDocuments
                  pack={{ documentsList: documents, buttons: RenderAction }}
                  buttonHeader={() => {
                    setIsCreateOrEditDocument(true);
                    setTypeDocumentIdEditing(0);
                  }}
                />
              </main>
            </TabDocuments>
          )}

          {tabActived === 2 && (
            <TabAccess className='tab-pane active'>
              <div>
                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={enableRandomSecurityCheck}
                      onChange={(e) => {
                        setEnableRandomSecurityCheck(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_MAGAZINE' })}</span>
                  </div>
                  <p>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_MAGAZINE_DESC' })}</p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={enableCreditControl}
                      onChange={(e) => {
                        setEnableCreditControl(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({
                        id: 'CONFIG_TAB_ACCESS_CREDIT_CONTROL'
                      })}
                    </span>
                  </div>

                  <p>
                    {intl.formatMessage({
                      id: 'CONFIG_TAB_ACCESS_CREDIT_CONTROL_DESC'
                    })}
                  </p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={enableAccessMethodsConfig}
                      onChange={(e) => {
                        setEnableAccessMethodsConfig(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_METHODS' })}</span>
                  </div>

                  <p>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_METHODS_DESC' })}</p>
                </div>
              </div>

              <div>
                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={enableDeviceInAcessRules}
                      onChange={(e) => {
                        setEnableDeviceInAcessRules(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_ASSOCIATE_DEVICES' })}
                    </span>
                  </div>
                  <p>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_ASSOCIATE_DEVICES_DESC' })}</p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={enableResetStatusCurrentPeoples}
                      onChange={(e) => {
                        setEnableResetStatusCurrentPeoples(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_RESET' })}</span>
                  </div>
                  <p>{intl.formatMessage({ id: 'CONFIG_TAB_ACCESS_RESET_DESC' })}</p>
                </div>



                {enableResetStatusCurrentPeoples && (
                  <div className='one-config'>
                    <div style={{ marginTop: '15px', display: 'block' }}>
                      <span style={{ display: 'block' }}>
                        {intl.formatMessage({ id: 'SETTINGS.ACCESS_LOGS.SELECT_RESET_TIME' })}
                      </span>
                      <input
                        type='time'
                        className='form-control resolve'
                        value={timeAntiDoubleEntry}
                        min='00:00'
                        max='23:59'
                        pattern='[0-2][0-9]:[0-5][0-9]'
                        onChange={(e: any) => {
                          setTimeAntiDoubleEntry(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={disableVisitorAccessingExternalArea}
                      onChange={(e) => {
                        setDisableVisitorAccessingExternalArea(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.ACCESS_LOGS.DISABLE_VISITOR_WHEN_ACCESSING_EXTERNAL_AREA' })}
                    </span>
                  </div>
                  <p>
                    {intl.formatMessage({
                      id: 'SETTINGS.ACCESS_LOGS.AUTOMATICALLY_INACTIVATES_THE_VISITOR_WHEN_ACCESSING_AN_EXTERNAL_AREA'
                    })}
                  </p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={disablePersonCardsWhenPersonInactive}
                      onChange={(e) => {
                        setDisablePersonCardsWhenPersonInactive(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.ACCESS_LOGS.UNLINK_CARDS_FROM_INACTIVATED_PEOPLE' })}
                    </span>
                  </div>
                  <p>{intl.formatMessage({ id: "SETTINGS.ACCESS_LOGS.REMOVES_THE_PERSON'S_REGISTERED_CARDS" })}</p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={disablePersonCardsWhenVisitorInactive}
                      onChange={(e) => {
                        setDisablePersonCardsWhenVisitorInactive(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.ACCESS_LOGS.UNLINK_CARDS_FROM_INACTIVATED_VISITORS' })}
                    </span>
                  </div>
                  <p>{intl.formatMessage({ id: 'SETTINGS.ACCESS_LOGS.REMOVE_REGISTERED_VISITOR_CARDS' })}</p>
                </div>

                {/* <div className='one-config'>
                  <div style={{marginTop: '15px'}}>
                    <ToggleSwitch
                      // checked={disableVisitorAccessingExternalArea}
                      onChange={(e) => {
                        // setDisableVisitorAccessingExternalArea(e.target.checked);
                      }}
                    />
                    <span style={{display: 'block'}}>Segregação Lógica</span>
                  </div>
                  <p>Quando ativado separa os dados entre empresas</p>
                </div> */}
              </div>

              <div>
                <div className='one-config' style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={inactivationTime.isEnabled}
                      onChange={(e) => {
                        const isEnabled = e.target.checked;
                        setInactivationTime(isEnabled ? { isEnabled } : { isEnabled, value: 0 });
                      }}
                    />
                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'AUTO_INACTIVATE_BY_TIME.TOGGLE' })}</span>
                  </div>
                  <p>{intl.formatMessage({ id: 'AUTO_INACTIVATE_BY_TIME.TOGGLE.LABEL' })}</p>

                  {inactivationTime.isEnabled && (
                    <>

                      <Select
                        intl={intl}
                        value={targetAutomaticPersonInactivation}
                        setValue={setTargetAutomaticPersonInactivation}
                      />


                      <Input
                        label={intl.formatMessage({ id: 'AUTO_INACTIVATE_BY_TIME.INPUT.LABEL' })}
                        id='inactivationTime'
                        placeholder={intl.formatMessage({ id: 'AUTO_INACTIVATE_BY_TIME.INPUT.PLACEHOLDER' })}
                        onChange={(e) => {
                          if (/^(?:\d*\.?\d+|)$/.test(e.target.value)) {
                            setInactivationTime({
                              value: +e.target.value
                            });
                          }
                        }}
                        value={inactivationTime.value}
                        style={{ flexDirection: 'column', gap: 0, marginTop: '10px' }}
                        error={inactivationTime.error}
                      />


                    </>
                  )}
                </div>


                <div className='one-config' style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={automaticInactivationDeadline}
                      onChange={(e) => {
                        const isEnabled = e.target.checked;
                        setAutomaticInactivationDeadline(isEnabled);
                      }}
                    />
                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG.AUTOMATIC.Inactivation.Deadline' })}</span>
                  </div>
                  <p>{intl.formatMessage({ id: 'CONFIG.AUTOMATIC.Inactivation.Deadline.desc' })}</p>

                  {
                    automaticInactivationDeadline && (
                      <Select
                        intl={intl}
                        value={targetInactivationAfterExpire}
                        setValue={setTargetInactivationAfterExpire}
                      />
                    )
                  }

                </div>
              </div>

              <div>
                <div className='one-config-2' style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={qrCodeActive}
                      onChange={(e) => {
                        setQrCodeActive(e.target.checked);
                        if (!e.target.checked) {
                          setQrCodeMessage('');
                        }
                      }}
                    />
                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG.ENABLE_SENDING_MESSAGE' })}</span>
                  </div>
                  <p>{intl.formatMessage({ id: 'CONFIG.ENABLE_SENDING_MESSAGE_DESCRIPTION' })}</p>

                  {qrCodeActive && (
                    <>
                      {/* <Input
                        label={intl.formatMessage({ id: 'CONFIG.ENABLE_SENDING_MESSAGE_LABEL' })}
                        id='inactivationTime'
                        placeholder={intl.formatMessage({ id: 'CONFIG.ENABLE_SENDING_MESSAGE_LABEL' })}
                        onChange={(e) => setQrCodeMessage(e.target.value)}
                        value={qrCodeMessage}
                        style={{ flexDirection: 'column', gap: 0 }}
                        error={qrCodeErro}
                      /> */}
                      <textarea
                        className='text-area-qr-code-message'
                        value={qrCodeMessage}
                        onChange={(e) => {
                          setQrCodeMessage(e.target.value);
                          setQrCodeErroBoolean(false);
                        }}
                      />
                      {qrCodeErroBoolean ? <span style={{ fontSize: '11px', color: '#d32f2f' }}>{qrCodeErro}</span> : ''}
                      {/* <TextField
                        id='standard-textarea'
                        onChange={(e) => {
                          setQrCodeMessage(e.target.value);
                          setQrCodeErroBoolean(false);
                        }}
                        value={qrCodeMessage}
                        label={intl.formatMessage({id: 'CONFIG.ENABLE_SENDING_MESSAGE_LABEL'})}
                        placeholder='Placeholder'
                        multiline
                        margin='normal'
                        fullWidth
                        variant='filled'
                        error={qrCodeErroBoolean}
                        helperText={qrCodeErroBoolean ? qrCodeErro : ''}
                      /> */}
                    </>
                  )}

                  <div className='one-config-2'>
                    <div style={{ marginTop: '15px' }}>
                      <ToggleSwitch
                        checked={defaultMessageActive}
                        onChange={(e) => {
                          setDefaultMessageActive(e.target.checked);
                          if (!e.target.checked) {
                            setDefaultMessage('');
                          }
                        }}
                      />
                      <span style={{ display: 'block' }}>
                        {intl.formatMessage({ id: 'Enable.default.message.for.black.list' })}
                      </span>
                    </div>
                    <p>
                      {intl.formatMessage({
                        id:
                          'Quando habilitado, permite que você cadastre uma mensagem personalizada que será exibida nos dispositivos quando uma pessoa/visitante estiver na lista de bloqueio.'
                      })}
                    </p>
                  </div>

                  {defaultMessageActive && (
                    <div className='one-config-2'>
                      <div style={{ marginTop: '15px', display: 'block' }}>
                        <span style={{ display: 'block' }}>
                          {intl.formatMessage({ id: 'Mensagem padrão da lista de bloqueio' })}
                        </span>
                        <textarea
                          className='text-area-default-message'
                          value={defaultMessage}
                          onChange={(e) => {
                            setDefaultMessage(e.target.value);
                            setDefaultMessageBoolean(false);
                          }}
                        />
                        {defaultMessageBoolean ? (
                          <span style={{ fontSize: '11px', color: '#d32f2f' }}>{defaultMessageErro}</span>
                        ) : (
                          ''
                        )}
                        {/* <TextField
                        id='standard-textarea'
                        onChange={(e) => {
                          setQrCodeMessage(e.target.value);
                          setQrCodeErroBoolean(false);
                        }}
                        value={qrCodeMessage}
                        label={intl.formatMessage({id: 'Mensagem padrão da lista de bloqueio'})}
                        placeholder='Placeholder'
                        multiline
                        margin='normal'
                        fullWidth
                        variant='outlined'
                        error={qrCodeErroBoolean}
                        helperText={qrCodeErroBoolean ? qrCodeErro : ''}
                      /> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>

                {
                  enableRandomSecurityCheck && (
                    <>
                      <div className='one-config'>
                        <div style={{ marginTop: '15px', display: 'block' }}>

                          <span style={{ display: 'block' }}>
                            {intl.formatMessage({
                              id: 'Período de bloqueio de inspeção aleatória'
                            })}
                          </span>

                          <input
                            type='number'
                            className='form-control resolve'
                            value={randomInspectLockedPeriod}
                            onChange={(e: any) => {
                              setRandomInspectLockedPeriod(e.target.value);
                            }}
                          />
                        </div>


                      </div>

                      <div className='one-config'>
                        <div style={{ marginTop: '38px' }}>
                          <ToggleSwitch
                            checked={randomInspectActivateRelay}
                            onChange={(e) => {
                              setRandomInspectActivateRelay(e.target.checked);
                            }}
                          />
                          <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'RandomInspectActivateRelay' })}</span>
                        </div>


                      </div>
                    </>
                  )
                }

              </div>
            </TabAccess>
          )}

          {tabActived === 6 && (
            <TabAccess className='tab-pane active'>
              <div>
                <div className='one-config' style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={webHook}
                      onChange={(e) => {
                        setWebhook(e.target.checked);
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.INTEGRATIONS.INTEGRATION_VIA_WEBHOOK' })}
                    </span>
                  </div>
                  <p>
                    {intl.formatMessage({ id: 'SETTINGS.INTEGRATIONS.WHEN_ACTIVE_ALLOWS_INTEGRATION_VIA_WEBHOOK' })}
                    <br />
                  </p>

                  {webHook && (
                    <Input
                      label={intl.formatMessage({ id: 'IMPORT.TOOLTIP.ADDRESS' })}
                      id='inactivationTime'
                      placeholder={intl.formatMessage({ id: 'WEBHOOK.PLACEHOLDER' })}
                      value={webHookURL}
                      onChange={(e) => {
                        setWebhookURL(e.target.value);
                      }}
                      // value={inactivationTime.value}
                      style={{ flexDirection: 'column', gap: 0, marginTop: '17px' }}
                    // error={inactivationTime.error}
                    />
                  )}
                </div>
              </div>
            </TabAccess>
          )}

          {tabActived === 5 && (
            <TabAccess className='tab-pane active'>
              <div>
                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={remoteOnBoardingRequiredFields.email}
                      onChange={(e) => {
                        setRemoteOnBoardingRequiredFields({ email: e.target.checked });
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.REMOTE_REGISTRATION.MAKE_EMAIL_MANDATORY' })}
                    </span>
                  </div>
                  <p>
                    {intl.formatMessage({ id: 'SETTINGS.REMOTE_REGISTRATION.REQUIRES_THE_VISITOR_TO_PROVIDE_AN_EMAIL' })}
                  </p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={remoteOnBoardingRequiredFields.personPhoto}
                      onChange={(e) => {
                        setRemoteOnBoardingRequiredFields({ personPhoto: e.target.checked });
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({
                        id: 'SETTINGS.REMOTE_REGISTRATION.MAKE_PHOTO_MANDATORY'
                      })}
                    </span>
                  </div>

                  <p>
                    {intl.formatMessage({
                      id: 'SETTINGS.REMOTE_REGISTRATION.REQUIRES_THE_VISITOR_TO_PROVIDE_A_PHOTO'
                    })}
                  </p>
                </div>

                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={remoteOnBoardingRequiredFields.personDocuments}
                      onChange={(e) => {
                        setRemoteOnBoardingRequiredFields({ personDocuments: e.target.checked });
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.REMOTE_REGISTRATION.MAKE_DOCUMENT_MANDATORY' })}
                    </span>
                  </div>

                  <p>
                    {intl.formatMessage({
                      id: 'SETTINGS.REMOTE_REGISTRATION.REQUIRES_THE_VISITOR_TO_PROVIDE_A_DOCUMENT'
                    })}
                  </p>
                </div>
              </div>

              <div>
                <div className='one-config'>
                  <div style={{ marginTop: '15px' }}>
                    <ToggleSwitch
                      checked={remoteOnBoardingRequiredFields.phoneNumber}
                      onChange={(e) => {
                        setRemoteOnBoardingRequiredFields({ phoneNumber: e.target.checked });
                      }}
                    />
                    <span style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'SETTINGS.REMOTE_REGISTRATION.MAKE_PHONE_MANDATORY' })}
                    </span>
                  </div>
                  <p>
                    {intl.formatMessage({
                      id: 'SETTINGS.REMOTE_REGISTRATION.REQUIRES_THE_VISITOR_TO_PROVIDE_A_PHONE_NUMBER'
                    })}
                  </p>
                </div>


                <div className='one-config'>
                  <label>{intl.formatMessage({ id: 'LABEL.VISITOR.OTHER.OPTION' })}</label>
                  <input type="text" className='form-control' value={personalizedMessageVisitorLabel} onChange={({ target }) => {
                    setPersonalizedMessageVisitorLabel(target.value)
                  }} />
                </div>

              </div>
            </TabAccess>
          )}
          {tabActived === 7 && (
            <TabAccess className='tab-pane active'>
              <div className='one-config'>
                <div style={{ marginTop: '15px' }}>
                  <ToggleSwitch
                    checked={visitorControlToggle}
                    onChange={(e) => {
                      setVisitorControlToggle(e.target.checked);
                    }}
                  />
                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'Habilitar Controle de Visitas' })}</span>
                </div>
                <p>
                  {intl.formatMessage({
                    id:
                      'Quando habilitado, irá solicitar o registro da Empresa e Pessoa visitada no cadastro de visitantes.'
                  })}
                </p>
              </div>
              {visitorControlToggle ? (
                <div>
                  <div className='col-lg-5'>
                    <label>{intl.formatMessage({ id: 'Tipo de Controle ' })}</label>
                    <select
                      value={visitorControlSelect}
                      name='documentDefaultToPerson'
                      className='custom-select'
                      onChange={(e: any) => setVisitorControlSelect(Number(e.target.value))}>
                      <option value={0}>Empresa e pessoa visitada devem estar previamente cadastradas</option>
                      <option value={1}>Permite digitar livremente o nome da pessoa visitada</option>
                    </select>
                  </div>
                </div>
              ) : null}
            </TabAccess>
          )}

          {
            tabActived === 8 && (
              <InputsConfigs
                values={
                  inputsConfiguration
                }
                alterValues={handlerAlterInputConfig}
              />
            )
          }



          <Show show={tabActived === 3}>
            <LicensesTab />
          </Show>



          <Footer>
            <button className='btn btn-light'>{intl.formatMessage({ id: 'CONFIG.BUTTON_CANCEL' })}</button>
            <button disabled={isRead || isSaveProgress} className='btn btn-primary btn-elevate' onClick={handleSave}>
              {isSaveProgress ? intl.formatMessage({ id: 'BTN.SAVING.TEXT' }) : intl.formatMessage({ id: 'CONFIG.BUTTON_SAVE' })}
            </button>
          </Footer>

          <Modal
            show={isCreateOrEditDocument}
            onHide={() => {
              setIsCreateOrEditDocument(false);
              setTypeDocumentIsBlock(false);
              setTypeDocumentIsImage(false);
              setTypeDocumentBlockUserIsDocumentDateExpired(false);
              setTypeDocument('');
              setTypeDocumentIdEditing(0);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_ADD' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <span>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_TABLE_ONE_COLUMN' })}</span>
                  <input
                    type='text'
                    className='form-control'
                    value={typeDocument}
                    onChange={(e) => {
                      setTypeDocument(e.target.value);
                    }}
                  />
                </div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <ToggleSwitch
                    checked={typeDocumentIsBlock}
                    onChange={(e) => {
                      setTypeDocumentIsBlock(e.target.checked);

                      if (e.target.checked === false) {
                        setTypeDocumentBlockUserIsDocumentDateExpired(false);
                      }
                    }}
                  />
                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_ONE' })}</span>
                </div>
                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <ToggleSwitch
                    checked={allowDuplicity}
                    onChange={(e) => {
                      setAllowDuplicity(e.target.checked);

                      if (e.target.checked === false) {
                        // setTypeDocumentBlockUserIsDocumentDateExpired(false);
                        setAllowDuplicity(false);
                      }
                    }}
                  />
                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_THREE' })}</span>
                </div>

                {typeDocumentIsBlock && (
                  <div style={{ marginTop: '15px', display: 'flex' }}>
                    <ToggleSwitch
                      checked={typeDocumentBlockUserIsDocumentDateExpired}
                      onChange={(e) => {
                        setTypeDocumentBlockUserIsDocumentDateExpired(e.target.checked);
                      }}
                    />

                    <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_TWO' })}</span>
                  </div>
                )}

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <ToggleSwitch
                    checked={typeDocumentIsImage}
                    onChange={(e) => {
                      setTypeDocumentIsImage(e.target.checked);
                    }}
                  />

                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG_TAB_FILES_TOGGLE_TREE' })}</span>
                </div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <ToggleSwitch
                    checked={documentRequiredInPersons}
                    onChange={(e) => {
                      setDocumentRequiredInPersons(e.target.checked);
                    }}
                  />

                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG.DOCUMENT.REQUIRED.FOR.PERSON' })}</span>
                </div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <ToggleSwitch
                    checked={documentRequiredInVisitors}
                    onChange={(e) => {
                      setDocumentRequiredInVisitors(e.target.checked);
                    }}
                  />

                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG.DOCUMENT.REQUIRED.FOR.VISITOR' })}</span>
                </div>

                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <ToggleSwitch
                    checked={checkDocumentValidation}
                    onChange={(e) => {
                      setCheckDocumentValidation(e.target.checked);
                    }}
                  />

                  <span style={{ display: 'block' }}>{intl.formatMessage({ id: 'CONFIG.DOCUMENT.REQUIRED.VALIDATION' })}</span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  setIsCreateOrEditDocument(false);
                  setTypeDocumentIsBlock(false);
                  setTypeDocumentIsImage(false);
                  setTypeDocumentBlockUserIsDocumentDateExpired(false);
                  setTypeDocument('');
                  setTypeDocumentIdEditing(0);
                }}>
                {intl.formatMessage({ id: 'CONFIG.BUTTON_CANCEL' })}
              </Button>
              <Button
                variant='primary'
                disabled={isRead}
                onClick={typeDocumentIdEditing ? handle_document_edit : handle_document_add}>
                {typeDocumentIdEditing
                  ? intl.formatMessage({ id: 'EDIT' })
                  : intl.formatMessage({ id: 'PERSON.BUILDER.ADD_BUTTON' })}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modalDeleteIsShow}
            onHide={() => {
              setModalDeleteIsShow(false);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })} : {dataModal.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {intl.formatMessage({ id: 'CONFIG_TAB_FILES_REMOVE_TYPE_FILE' })} {dataModal.name} ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  setModalDeleteIsShow(false);
                }}>
                {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
              </Button>
              <Button
                variant='danger'
                disabled={isRead}
                onClick={async () => {
                  if (isRead) return;

                  setModalDeleteIsShow(false);
                  await handle_documente_delete();
                }}>
                {intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </CardBody>
    </Card>
  );
}
