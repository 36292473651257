class NameAndTypePDFs {
  getNameAndType() {
    switch (window.location.pathname) {

      case '/logs': {
        return {
          name: 'AccessLogs',
          nameToIntl: 'ASIDE.MENU.ITEM.ACCESS_LOGS',
          type: 1
        }
      }

      case '/logs/datetime': {
        return {
          name: 'AccessLogsByDateTime',
          nameToIntl: 'ASIDE.MENU.ITEM.DATETIME_ACCESS_LOGS',
          type: 2
        }
      }

      case '/logs/groupsandcompany': {
        return {
          name: 'AccessLogsByGroupAndCompanies',
          nameToIntl: 'ASIDE.MENU.ITEM.ACCESS_LOGS_BY_GROUP_AND_COMPANY',
          type: 3
        }
      }

      case '/logs/people': {
        return {
          name: 'AccessLogsByUsers',
          nameToIntl: 'ASIDE.MENU.ITEM.PEOPLE_ACCESS_LOGS',
          type: 4
        }
      }

      case '/logs/visits': {
        return {
          name: 'AccessLogsByVisitors',
          nameToIntl: 'ASIDE.MENU.ITEM.ACCESS_LOGS_GUEST_ACCESS',
          type: 5
        }
      }

      case '/logs/areas': {
        return {
          name: 'AccessLogsByAreas',
          nameToIntl: 'ASIDE.MENU.ITEM.AREAS_ACCESS_LOGS',
          type: 6
        }
      }

      case '/logs/devices': {
        return {
          name: 'AccessLogsByDevices',
          nameToIntl: 'ASIDE.MENU.ITEM.DEVICES_ACCESS_LOGS',
          type: 7
        }
      }

      case '/logs/auth': {
        return {
          name: 'AccessLogsByAuthorization',
          nameToIntl: 'ASIDE.MENU.ITEM.AUTH_ACCESS_LOGS',
          type: 8
        }
      }

      case '/logs/blackList': {
        return {
          name: 'AccessLogsByBlockedPeople',
          nameToIntl: 'ACCESS_LOGS.BLACK_LIST',
          type: 9
        }
      }

      case '/logs/presence-company-position': {
        return {
          name: 'AccessLogsTotalByUser',
          nameToIntl: 'PRESENCE.COMPANY.POSITION',
          type: 10
        }
      }

      case '/logs/number-access-person': {
        return {
          name: '',
          nameToIntl: 'REPORT.NUMBER.PERSON.ACCESS',
          type: 100
        }
      }


      case '/accesslogs/vehicle': {
        return {
          name: 'VehicleAccess',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.GENERAL',
          type: 11
        }
      }

      case '/logs/vehicle/datetime': {
        return {
          name: 'VehicleAccessByPeriod',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_DATE',
          type: 12
        }
      }

      case '/logs/vehicle/groupsandcompany': {
        return {
          name: 'VehicleAccessByGroupAndCompany',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_GROUP',
          type: 13
        }
      }

      case '/logs/vehicle/people': {
        return {
          name: 'VehicleAccessByPerson',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_PEOPLE',
          type: 14
        }
      }

      case '/logs/vehicle/byvehicle': {
        return {
          name: 'VehicleAccessByVehicles',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_VEHICLE',
          type: 15
        }
      }

      case '/logs/vehicle/parking': {
        return {
          name: 'VehicleAccessByParking',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_PARKING',
          type: 16
        }
      }

      case '/logs/vehicle/devices': {
        return {
          name: 'VehicleAccessByDevice',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_DEVICE',
          type: 17
        }
      }

      case '/logs/vehicle/auth': {
        return {
          name: 'VehicleAccessByAuthorization',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_AUTH',
          type: 18
        }
      }

      case '/centerlogpermissions/persons': {
        return {
          name: 'AccessPermissionsByUsers',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_PEOPLE',
          type: 19
        }
      }

      case '/centerlogpermissions/companies': {
        return {
          name: 'AccessPermissionsByCompanies',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_COMPANIES',
          type: 20
        }
      }

      case '/centerlogpermissions/personsgroups': {
        return {
          name: 'AccessPermissionsByGroups',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_GROUPPEOPLE',
          type: 21
        }
      }

      case '/centerlogpermissions/personstoarea': {
        return {
          name: 'UserPermissionsByArea',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_PEOPLEAREA',
          type: 22
        }
      }

      case '/centerlogpermissions/companiestoarea': {
        return {
          name: 'GroupPermissionsByArea',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_GROUPAREA',
          type: 23
        }
      }
      case '/peopleRecords': {
        return {
          name: 'UsersRecords',
          nameToIntl: 'LOGREGISTER.PEOPLE',
          type: 24
        }
      }
      case '/visitorsRecords': {
        return {
          name: 'VisitorsRecords',
          nameToIntl: 'LOGREGISTER.VISITANT',
          type: 25
        }
      }
      case '/peopleRecordsByPeopleGroup': {
        return {
          name: 'UsersRecordsByGroups',
          nameToIntl: 'LOGREGISTER.GRUOP_PEOPLE',
          type: 26
        }
      }
      case '/peopleRecordsByCompanies': {
        return {
          name: 'UsersRecordsByCompanies',
          nameToIntl: 'LOGREGISTER.BUNNISSES',
          type: 27
        }
      }
      case '/visitorsRecordsByPeopleGroup': {
        return {
          name: 'VisitorsRecordsByGroups',
          nameToIntl: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_VISITORS_GROUP',
          type: 28
        }
      }
      case '/visitorsRecordsByCompanies': {
        return {
          name: 'VisitorsRecordsByCompanies',
          nameToIntl: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_COMPANIES',
          type: 29
        }
      }
      case '/creditbypeople': {
        return {
          name: 'CreditRegistrationsByUser',
          nameToIntl: 'REGISTER_CREDIT_TO_USER_TITLE',
          type: 30
        }
      }
      case '/expiredDocuments': {
        return {
          name: 'RegisterOfExpiredDocuments',
          nameToIntl: 'ASIDE.MENU.ITEM.REGISTER_EXPIRED_DOCUMENTS',
          type: 31
        }
      }
      case '/badlogin': {
        return {
          name: 'RecordsOfBadLoginAttempts',
          nameToIntl: 'BADLOGIN_TITLE',
          type: 32
        }
      }
      case '/photosRegister': {
        return {
          name: 'UserPhotoRegistration',
          nameToIntl: 'REPORTS.PHOTOS',
          type: 33
        }
      }
      case '/blackListRegister': {
        return {
          name: 'PeopleRecordsFromTheBlackList',
          nameToIntl: 'REPORTS.BLACK_LIST',
          type: 34
        }
      }

      case '/PeopleVisitorsPresentCompany': {
        return {
          name: 'ActualLocationByCompanyAndProfession',
          nameToIntl: 'REPORTS.CURRENT_STATUS_PEOPLE_VISITORS_PRESENT_COMPANY',
          type: 35
        }
      }

      case '/locationPeoples': {
        return {
          name: 'PeopleLocation',
          nameToIntl: 'REPORTS.CURRENT_STATUS_LOCATION_PEOPLE',
          type: 36
        }
      }

      case '/locationVisitors': {
        return {
          name: 'VisitorsLocation',
          nameToIntl: 'REPORTS.CURRENT_STATUS_LOCATION_VISITORS',
          type: 37
        }
      }

      case '/visitorsExpireds': {
        return {
          name: 'ExpiredVisitors',
          nameToIntl: 'REPORTS.CURRENT_STATUS_EXPIRED_VISITORS',
          type: 38
        }
      }

      case '/LengthStayCurrentArea': {
        return {
          name: 'StayTimeInCurrentArea',
          nameToIntl: 'REPORTS.CURRENT_STATUS_LENGTH_STAY_CURRENT_AREA',
          type: 39
        }
      }

      case '/lengthStayCurrentParking': {
        return {
          name: 'StayTimeInCurrentParking',
          nameToIntl: 'REPORTS.CURRENT_PARKING_LENGTH_OF_STAY',
          type: 40
        }
      }

      case '/lengthStayCurrentVehicleLocal': {
        return {
          name: 'VehicleLocation',
          nameToIntl: 'LOGREGISTER.VEHICLE_LOCATION',
          type: 41
        }
      }

      case '/auditlogs': {
        return {
          name: 'AuditLogs',
          nameToIntl: 'REPORTS.AUDITLOG',
          type: 42
        }
      }

      case '/logs/accesses-with-details-of-credit-usage': {
        return {
          name: 'AccessLogsWithCreditDetail',
          nameToIntl: 'ACCESS.LOG.DETAILS.USE.CREDITS',
          type: 43
        }
      }


      default: {
        return {
          name: ' - ',
          nameToIntl: ' - ',
          type: 0
        }
      }
    }
  }

  getOnlyNames(type: number) {
    switch (type) {

      case 1: {
        return {
          name: 'AccessLogs',
          nameToIntl: 'ASIDE.MENU.ITEM.ACCESS_LOGS',
          type: 1
        }
      }

      case 2: {
        return {
          name: 'AccessLogsByDateTime',
          nameToIntl: 'ASIDE.MENU.ITEM.DATETIME_ACCESS_LOGS',
          type: 2
        }
      }

      case 3: {
        return {
          name: 'AccessLogsByGroupAndCompanies',
          nameToIntl: 'ASIDE.MENU.ITEM.ACCESS_LOGS_BY_GROUP_AND_COMPANY',
          type: 3
        }
      }

      case 4: {
        return {
          name: 'AccessLogsByUsers',
          nameToIntl: 'ASIDE.MENU.ITEM.PEOPLE_ACCESS_LOGS',
          type: 4
        }
      }

      case 5: {
        return {
          name: 'AccessLogsByVisitors',
          nameToIntl: 'ASIDE.MENU.ITEM.ACCESS_LOGS_GUEST_ACCESS',
          type: 5
        }
      }

      case 6: {
        return {
          name: 'AccessLogsByAreas',
          nameToIntl: 'ASIDE.MENU.ITEM.AREAS_ACCESS_LOGS',
          type: 6
        }
      }

      case 7: {
        return {
          name: 'AccessLogsByDevices',
          nameToIntl: 'ASIDE.MENU.ITEM.DEVICES_ACCESS_LOGS',
          type: 7
        }
      }

      case 8: {
        return {
          name: 'AccessLogsByAuthorization',
          nameToIntl: 'ASIDE.MENU.ITEM.AUTH_ACCESS_LOGS',
          type: 8
        }
      }

      case 9: {
        return {
          name: 'AccessLogsByBlockedPeople',
          nameToIntl: 'ACCESS_LOGS.BLACK_LIST',
          type: 9
        }
      }

      case 10: {
        return {
          name: 'AccessLogsTotalByUser',
          nameToIntl: 'PRESENCE.COMPANY.POSITION',
          type: 10
        }
      }

      case 100: {
        return {
          name: '',
          nameToIntl: 'REPORT.NUMBER.PERSON.ACCESS',
          type: 100
        }
      }

      case 11: {
        return {
          name: 'VehicleAccess',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.GENERAL',
          type: 11
        }
      }

      case 12: {
        return {
          name: 'VehicleAccessByPeriod',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_DATE',
          type: 12
        }
      }

      case 13: {
        return {
          name: 'VehicleAccessByGroupAndCompany',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_GROUP',
          type: 13
        }
      }

      case 14: {
        return {
          name: 'VehicleAccessByPerson',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_PEOPLE',
          type: 14
        }
      }

      case 15: {
        return {
          name: 'VehicleAccessByVehicles',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_VEHICLE',
          type: 15
        }
      }

      case 16: {
        return {
          name: 'VehicleAccessByParking',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_PARKING',
          type: 16
        }
      }

      case 17: {
        return {
          name: 'VehicleAccessByDevice',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_DEVICE',
          type: 17
        }
      }

      case 18: {
        return {
          name: 'VehicleAccessByAuthorization',
          nameToIntl: 'ACCESS_VEHICLES_LOGS.BY_AUTH',
          type: 18
        }
      }

      case 19: {
        return {
          name: 'AccessPermissionsByUsers',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_PEOPLE',
          type: 19
        }
      }

      case 20: {
        return {
          name: 'AccessPermissionsByCompanies',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_COMPANIES',
          type: 20
        }
      }

      case 21: {
        return {
          name: 'AccessPermissionsByGroups',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_GROUPPEOPLE',
          type: 21
        }
      }

      case 22: {
        return {
          name: 'UserPermissionsByArea',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_PEOPLEAREA',
          type: 22
        }
      }

      case 23: {
        return {
          name: 'GroupPermissionsByArea',
          nameToIntl: 'LOGSPERMISSIONS_VIEW_GROUPAREA',
          type: 23
        }
      }

      case 24: {
        return {
          name: 'UsersRecords',
          nameToIntl: 'LOGREGISTER.PEOPLE',
          type: 24
        }
      }
      case 25: {
        return {
          name: 'VisitorsRecords',
          nameToIntl: 'LOGREGISTER.VISITANT',
          type: 25
        }
      }
      case 26: {
        return {
          name: 'UsersRecordsByGroups',
          nameToIntl: 'LOGREGISTER.GRUOP_PEOPLE',
          type: 26
        }
      }
      case 27: {
        return {
          name: 'UsersRecordsByCompanies',
          nameToIntl: 'LOGREGISTER.BUNNISSES',
          type: 27
        }
      }
      case 28: {
        return {
          name: 'VisitorsRecordsByGroups',
          nameToIntl: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_VISITORS_GROUP',
          type: 28
        }
      }
      case 29: {
        return {
          name: 'VisitorsRecordsByCompanies',
          nameToIntl: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_COMPANIES',
          type: 29
        }
      }
      case 30: {
        return {
          name: 'CreditRegistrationsByUser',
          nameToIntl: 'REGISTER_CREDIT_TO_USER_TITLE',
          type: 30
        }
      }
      case 31: {
        return {
          name: 'RegisterOfExpiredDocuments',
          nameToIntl: 'ASIDE.MENU.ITEM.REGISTER_EXPIRED_DOCUMENTS',
          type: 31
        }
      }
      case 32: {
        return {
          name: 'RecordsOfBadLoginAttempts',
          nameToIntl: 'BADLOGIN_TITLE',
          type: 32
        }
      }
      case 33: {
        return {
          name: 'UserPhotoRegistration',
          nameToIntl: 'REPORTS.PHOTOS',
          type: 33
        }
      }
      case 34: {
        return {
          name: 'PeopleRecordsFromTheBlackList',
          nameToIntl: 'REPORTS.BLACK_LIST',
          type: 34
        }
      }

      case 35: {
        return {
          name: 'ActualLocationByCompanyAndProfession',
          nameToIntl: 'REPORTS.CURRENT_STATUS_PEOPLE_VISITORS_PRESENT_COMPANY',
          type: 35
        }
      }

      case 36: {
        return {
          name: 'PeopleLocation',
          nameToIntl: 'REPORTS.CURRENT_STATUS_LOCATION_PEOPLE',
          type: 36
        }
      }

      case 37: {
        return {
          name: 'VisitorsLocation',
          nameToIntl: 'REPORTS.CURRENT_STATUS_LOCATION_VISITORS',
          type: 37
        }
      }

      case 38: {
        return {
          name: 'ExpiredVisitors',
          nameToIntl: 'REPORTS.CURRENT_STATUS_EXPIRED_VISITORS',
          type: 38
        }
      }

      case 39: {
        return {
          name: 'StayTimeInCurrentArea',
          nameToIntl: 'REPORTS.CURRENT_STATUS_LENGTH_STAY_CURRENT_AREA',
          type: 39
        }
      }

      case 40: {
        return {
          name: 'StayTimeInCurrentParking',
          nameToIntl: 'REPORTS.CURRENT_PARKING_LENGTH_OF_STAY',
          type: 40
        }
      }

      case 41: {
        return {
          name: 'VehicleLocation',
          nameToIntl: 'LOGREGISTER.VEHICLE_LOCATION',
          type: 41
        }
      }

      case 42: {
        return {
          name: 'AuditLogs',
          nameToIntl: 'REPORTS.AUDITLOG',
          type: 42
        }
      }

      case 43: {
        return {
          name: 'AccessLogsWithCreditDetail',
          nameToIntl: 'ACCESS.LOG.DETAILS.USE.CREDITS',
          type: 43
        }
      }





      default: {
        return {
          name: ' - ',
          nameToIntl: ' - ',
          type: 0
        }
      }
    }
  }
}

export const nameAndTypePDFs = new NameAndTypePDFs()