import Axios from 'axios';
import { getUnixTime } from 'date-fns';
import qs from 'qs';
import { TFilterAlarmHistory } from 'src/app/modules/RegisterLogs/components/AlarmHistory/hooks/useQueryAlarmState';
import { AlarmCauses } from 'src/app/modules/RegisterLogs/components/AlarmHistory/types';
import { BlockPersonLog } from 'src/app/modules/Reports/BlackListLogs/domain/entity';
import { IBlockPersonLogRepo } from 'src/app/modules/Reports/BlackListLogs/domain/repository';
import { TParams, BlockPersonLogList } from 'src/app/modules/Reports/BlackListLogs/domain/useCase';
import { PersonType } from './reports';
import { idsecureAPI } from './devicesCrud';
import { formatFullDateStyle } from 'src/utils/formatFullDateStyle';
import { defaultDateTime } from 'src/utils/defaultDateTime';

const API_URL = process.env.REACT_APP_IDSECURE_API_URL_REPORTS;

export const GET_CREDITS_DETAILS = (params: any, transform = true) => {
  return Axios.get(`${API_URL}/accesslog/logsWithCreditDetail`, {
    params: {
      ...params,
      getPhotos: false
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.data.data.map((v: any) => {
          return {
            ...v,
            time: defaultDateTime.labelDateTime(v.time)
          }
        })

        response.data.data = transformValues

        if (transform) {
          return {
            data: {
              ...response.data,
              data: transformValues
            }
          }
        }

        return response
      } catch {
        return response
      }


    }],
    paramsSerializer: (prms) => {
      return qs.stringify(prms, { arrayFormat: 'repeat' });
    }
  });
}

export const getAccessLogs = (params: any, transform = true) => {

  return Axios.get(`${API_URL}/accesslog/logs`, {
    params: {
      ...params,
      getPhotos: window.controlID.modules.logs.disablePhotoLogs
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.data.data.map((v: any) => {
          return {
            ...v,
            time: defaultDateTime.labelDateTime(v.time)
          }
        })

        response.data.data = transformValues

        if (transform) {
          return {
            data: {
              ...response.data,
              data: transformValues
            }
          }
        }

        return response
      } catch {
        return response
      }


    }],
    paramsSerializer: (prms) => {
      return qs.stringify(prms, { arrayFormat: 'repeat' });
    }
  });
};
export const getVehiclesAccessLogs = (params: any) => {
  return Axios.get(`${API_URL}/vehicleAccesslog/logs`, {
    params,
    paramsSerializer: (prms) => {
      return qs.stringify(prms, { arrayFormat: 'repeat' });
    },
    transformResponse: [function (data) {

      const response = JSON.parse(data)

      try {
        const transformValues = response.data.data.map((v: any) => {
          return {
            ...v,
            time: defaultDateTime.labelDateTime(v.time)
          }
        })

        response.data.data = transformValues


        return {
          data: {
            ...response.data,
            data: transformValues
          }
        }



      } catch {
        return response
      }


    }],
  });
};

export function getAllAreas(params: any) {
  return Axios.get(`${API_URL}/accesslog/areas`, { params });
}

export function getAllDevices(params: any) {
  return Axios.get(`${API_URL}/accesslog/devices`, { params });
}

export function getAllPersons(params: any) {
  return Axios.get(`${API_URL}/accesslog/persons`, {
    params: {
      getPhotos: false,
      ...params
    }
  });
}

export function getAllVehicles(params: any) {
  return Axios.get(`${API_URL}/vehicles`, { params });
}

export function getAllParks(params: any) {
  return Axios.get(`${API_URL}/parkingLot`, { params });
}

export function findGroups(queryParams: any) {

  return Axios.get(`${API_URL}/accesslog/groups`, {
    params: queryParams
  });
}

export function withoutGroup(queryParams: any) {

  return Axios.get(`${API_URL}/persons/withoutGroup`, {
    params: queryParams
  });
}

type GET_LIST_ALL_CREDIT_TYPES_ACTIVED_PROPS = {
  pageSize: number;
  pageNumber: number;
  status: '1' | '2';
  sortOrder: 'asc';
  sortField: 'Description';
};

export function GET_LIST_ALL_CREDIT_TYPES_ACTIVED(params: GET_LIST_ALL_CREDIT_TYPES_ACTIVED_PROPS) {
  return Axios.get(`${API_URL}/credit/type`, {
    params
  });
}

export function GET_LIST_ALL_CREDIT_MANAGEMENT(params: any) {
  return Axios.get(`${API_URL}/reports/typeperson`, {
    params
  });
}

interface GET_ALL_LOGS_NOT_ACCESS_PROPS {
  PersonIds?: string;
  EndDate: string;
  StartDate: string;
  pageNumber: number;
  pageSize: number;
  sortOrder: string;
  sortField: string;
  culture?: string;
  status: number;
  Type?: number;
}

export function GET_ALL_LOGS_NOT_ACCESS(params: GET_ALL_LOGS_NOT_ACCESS_PROPS) {
  if (params.PersonIds === '') {
    delete params.PersonIds;
  }

  if (!params.Type) {
    delete params.Type;
  }

  return Axios.get(`${API_URL}/reports/invalidLoginAttempts`, {
    params
  });
}

export type TGetAlarmHistory = {
  message: number;
  success: boolean;
  data: {
    data: {
      time: string;
      deviceName: string;
      personName: string;
      event: number;
      cause: AlarmCauses;
    }[];
    total: number;
    page: number;
    limit: number;
    pages: number;
  };
};

export async function getAlarmHistory(query: TFilterAlarmHistory) {
  const params = {
    StartDate: getUnixTime(query.startDate),
    EndDate: getUnixTime(query.endDate),
    DeviceIds: query.device.join(','),
    Causes: query.alarms.join(','),
    pageNumber: query.page,
    pageSize: query.sizePerPage,
    sortField: 'Time',
    sortOrder: 'desc'
  };

  const { data } = await Axios.get<TGetAlarmHistory>(`${API_URL}/reports/alarmLogs`, { params });

  return data.data;
}

class BlockPersonLogRepo implements IBlockPersonLogRepo {
  private readonly url = `${API_URL}/accesslog/logs`;
  async getAll(params: TParams): Promise<BlockPersonLogList> {
    const paramsDTO = {
      personsIds: params.name?.map((name) => name.value) || undefined,
      pageNumber: params.page,
      pageSize: params.limit,
      sortField: 'Time',
      sortOrder: 'desc',
      exceptionList: true,
      getPhotos: window.controlID.modules.logs.disablePhotoLogs,
      dtStart: !!params?.startDate ? getUnixTime(params.startDate) : undefined,
      dtEnd: !!params?.endDate ? getUnixTime(params.endDate) : undefined
    };

    const { data } = await Axios.get(this.url, {
      params: paramsDTO,
      transformResponse: [function (data) {

        const response = JSON.parse(data)

        try {
          const transformValues = response.data.data.map((v: any) => {
            return {
              ...v,
              time: defaultDateTime.labelDateTime(v.time)
            }
          })

          response.data.data = transformValues


          return {
            data: {
              ...response.data,
              data: transformValues
            }
          }



        } catch {
          return response
        }


      }],
      paramsSerializer: (paramsDTO) => {
        return qs.stringify(paramsDTO, { arrayFormat: 'repeat' });
      }
    });

    const getPersonType = (type: string) => {
      if (type === 'Person') return PersonType.Person;

      return PersonType.Visitant;
    };

    const getDomainModelDTO = (data: Record<string, any>): BlockPersonLog => ({
      name: data.personName,
      img: data.personAvatar,
      accessDate: data.time,
      // accessDate: formatFullDateStyle(data.time),
      areaName: data.areaName,
      deviceName: data.deviceName,
      type: getPersonType(data.personType),
      event: data.event,
      registrationName: data.registrationName,
      professionalRole: data.professionalRole,
      notes: data.notes,
      groupDescription: data.groupDescription,
      companyDescription: data.companyDescription,
    });

    return {
      limit: data.data.limit,
      page: data.data.page,
      pages: data.data.pages,
      total: data.data.total,
      data: data.data.data.map(getDomainModelDTO)
    };
  }
}

export const blockPersonLogRepo = new BlockPersonLogRepo();
