import React from 'react'
import { IoCloseCircle } from 'react-icons/io5'
import { useIntl } from 'react-intl';

import { CardContainer } from "./styled";


import { useHistory, useLocation } from 'react-router-dom'
interface Props {
  data: {
    name: string,
    imageURL: string,
    eventClass: boolean,
    area: string,
    auth: string,
    datetime: string,
    deviceName: string,
    deviceImage: string,
    deviceId: number,
    userId?: number
  }
  openDoor: (id: number) => void
  hide: () => void,
  type?: string,
  noAreaLabel?: boolean
}
export function CardMonitor({ data, openDoor, hide, noAreaLabel, type }: Props) {
  const history = useHistory()
  const { pathname } = useLocation()
  const avatarImage = data.imageURL || '/media/users/default.jpg'

  const intl = useIntl()

  function textButton() {

    if (type) {

      if (+type === 6 || +type === 15) {

        return intl.formatMessage({ id: "PASS.OPEN" })
      } else {
        return intl.formatMessage({ id: "OPEN_DOOR_LABEL" })
      }
    } else {
      return intl.formatMessage({ id: "OPEN_DOOR_LABEL" })
    }
  }


  function toPage(id: number) {
    if (id) {
      if (pathname.includes('/vehiclemonitoring')) {
        history.push(`vehicles/edit/${String(id)}`)
      } else {
        history.push(`people/${String(id)}`)
      }
    }
  }


  return (
    <CardContainer isActived={data.eventClass}>


      <IoCloseCircle style={{ cursor: "pointer" }} className="buttonClose" color={data.eventClass ? "#64BEB5" : "#BE6664"} size={42} onClick={hide} />


      <header>
        <h1>{data.auth}</h1>
      </header>

      <div className='imageContainer'>
        <div>
          <img src={avatarImage} alt="" />
        </div>
      </div>

      <div className='details'>
        <strong style={data.userId ? { cursor: 'pointer' } : {}} onClick={() => toPage(data.userId || 0)}>{!data.name ? intl.formatMessage({ id: 'STATUS_PHOTO.UNKNOWN' }) : data.name}</strong>
        <span>{data.datetime}</span>
        <span>

          {
            !noAreaLabel && (
              <strong>{intl.formatMessage({ id: "AREA_LABEL" })}:</strong>
            )
          }
          {data.area}
        </span>
      </div>

      <footer>
        <p>{data.deviceName}</p>


        <div className='contianer-button'>
          <div>
            <img src={data.deviceImage} alt='' />
          </div>

          <button onClick={() => openDoor(data.deviceId)}>
            {textButton()}
          </button>
        </div>
      </footer>
    </CardContainer>
  )
}